
<template>
  <div
  class="
    flex
    items-center
    justify-center
    w-screen
    h-screen
    bg1
  "
>
  <div class="px-40 py-20  rounded-md shadow-xl">
    <div class="flex flex-col items-center">
      <h1 class="font-bold text-StemYellow text-9xl">404</h1>

      <h6 class="mb-2 text-2xl font-bold text-center text-StemBlue-800 md:text-3xl">
        <span class="text-red-500">Oops!</span> Page not found
      </h6>

      <p class="mb-8 text-center text-StemTextSecond-500 md:text-lg">
        The page you’re looking for doesn’t exist.
      </p>
 <router-link to="/">
      <button
        class="px-6 py-2 text-sm font-semibold text-white bg-StemBlue rounded-lg
            transition
            duration-500
            ease-in-out
            transform
            hover:-translate-y-1 hover:scale-110 hover:shadow-xl"
        >Go home</button>
      </router-link>
    </div>
  </div>
</div>
</template>

<script>
export default {

}
</script>

<style>
.bg1{
  background-image: url("./../../assets/images/StemLab404bg.jpg");
  background-repeat: no-repeat;
  background-size: 100%;
}

</style>