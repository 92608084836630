<template>
  <errorrScreen></errorrScreen>
</template>

<script>
import errorrScreen from '@/components/404/ErrorScreen.vue'
export default {
 components:{errorrScreen}
}
</script>

<style>

</style>